import React, { useEffect, useState } from 'react'
import { GoBack, Loader } from 'global/globalComponents'
import { useParams } from 'react-router-dom'
import { hideChatMessenger } from 'utils'
import { useSelector } from 'react-redux'
import axios from 'axios'

const ClientDashboardPreview = () => {
  const { clientId } = useParams()
  const allClients = useSelector(state => state.userClients.data)
  const [clientTokens, setClientTokens] = useState({
    loading: true,
    data: {
      accessToken: '',
      refreshToken: ''
    }
  })

  useEffect(() => {
    const client = allClients.find(client => client._id === clientId)

    if (client) {
      const fetchClientToken = async () => {
        try {
          const res = await axios({
            method: 'POST',
            url: '/user/login',
            data: {
              email: client.email,
              password: 'clientvenue@5678',
              agencyDomain:
                process.env.NODE_ENV === 'development'
                  ? 'roni.clientvenue.com'
                  : window.location.host
            },
            isPublic: true
          })

          setClientTokens({
            loading: false,
            data: {
              accessToken: res.data.token.accessToken,
              refreshToken: res.data.token.refreshToken
            }
          })
        } catch (err) {
          console.error(err?.response?.data?.message)
        }
      }

      fetchClientToken()
    }
  }, [clientId, allClients])

  useEffect(() => {
    hideChatMessenger()
  }, [])

  if (clientTokens.loading) return <Loader />

  return (
    <div className="w-full h-full flex justify-center items-center">
      <div
        className="h-full"
        style={{ width: '80vw', height: '80vh', margin: 'auto' }}
      >
        <div className="w-full mb-2">
          <GoBack path="/agency/clients/all" />
        </div>

        <iframe
          className="w-full h-full border-2 border-dashed border-gray-300"
          src={`${window.location.origin}/client/dashboard?temp_access_token=${clientTokens.data.accessToken}&temp_refresh_token=${clientTokens.data.refreshToken}`}
        ></iframe>
      </div>
    </div>
  )
}

export default ClientDashboardPreview
